import { css } from "styled-components"

export const override = css`
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 10px;
  height: 100%;
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);

  ul,
  ol {
    padding-left: 10px;
    margin-top: 10px;
  }

  li {
    margin-bottom: 5px;
  }

  * {
    user-select: text;
  }
`
