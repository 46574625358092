import React from "react"

const Placeholder = () => null

const Lazy = ({ component, ...rest }) => {
  const [Component, setComponent] = React.useState(() => Placeholder)

  React.useEffect(() => {
    import(component + ".js").then(Component =>
      setComponent(() => Component.default)
    )
  }, [component])
  return <Component {...rest} />
}
export default Lazy
