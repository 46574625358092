import React from "react"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import styled, { css } from "styled-components"
import { graphql } from "gatsby"

import Layout from "./Layout"
import Container from "./Container"
import Lazy from "./Lazy"
import Title from "./Title"
import Hero from "./Hero"

import { Image, Map, Details, Summary } from "./fragments"

import { override } from "../utils/style"

const LazyThree = props => <Lazy component="./fragments/3D" {...props} />
const LazyQuiz = props => <Lazy component="./fragments/Quiz" {...props} />

export const shortcodes = {
  Three: LazyThree,
  undefined: Image,
  Map,
  summary: Summary,
  details: Details,
  Quiz: LazyQuiz,
}

const MarkdownContainer = styled(Container)(override)

const MarkdownLayout = ({
  data: {
    mdx: {
      body,
      frontmatter: { title, cover },
    },
  },
}) => (
  <Layout>
    <MarkdownContainer>
      <Title>{title}</Title>
      {cover && <Hero src={cover.childImageSharp.fluid} />}
      <div
        css={css`
          font-size: 16px;
          color: #707070;
          flex: 1;
          position: relative;
          padding: 0 10px;
        `}
      >
        <MDXProvider components={shortcodes}>
          <MDXRenderer>{body}</MDXRenderer>
        </MDXProvider>
      </div>
    </MarkdownContainer>
  </Layout>
)

export default MarkdownLayout

export const query = graphql`
  query MarkdownQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        cover {
          publicURL
          childImageSharp {
            fluid(cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`
